import React from "react";
// @ts-ignore
import Layout from "../layouts";

const PersonalAccessPage = () => {
  return (
    <div>
      <title> Demander un accès anticipé à Coachello AI Coaching</title>
      <Layout>
        <h2 className="text-center pt-10">
          Demander un accès anticipé à Coachello AI Coaching
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <iframe
            title="Frühzeitigen Zugang für Coachello AI Coaching anfordern"
            style={{ width: "60%", height: "80%", border: "none" }}
            src="https://lo563s976ew.typeform.com/to/JuUC37Km"
            allowFullScreen
          ></iframe>
        </div>
      </Layout>
    </div>
  );
};

export default PersonalAccessPage;
